
.date-range {
  .vue-daterange-picker {
    width: 100%;

    &.ranges-only {
      /deep/ .daterangepicker {
        .calendars-container {
          display: none;
        }

        .ranges {
          width: 100%;
          position: relative;
        }
      }
    }

    /deep/ .daterangepicker {
      top: 40px;
      min-width: unset;
      white-space: nowrap;
    }

    &.opens-top {
      /deep/ .daterangepicker {
        top: -330px;

        &:before {
          top: unset;
          bottom: -7px;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
          border-bottom: none;
          border-top: 7px solid #ccc;
        }

        &:after {
          top: unset;
          bottom: -6px;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          border-bottom: none;
          border-top: 6px solid #fff;
        }
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .date-picker-input {
    white-space: nowrap;
  }

  .input-group > .input-group-append > .input-group-text {
    line-height: 1.3;
  }
}
